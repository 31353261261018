<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Sửa thành viên hát</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminKaraokeList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách thành viên hát
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Tên bài hát (*)</label>
              <input v-model="karaoke.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Lời bài hát <i class="far fa-question-circle"
                                                                        :uk-tooltip="'Chỉ ghi lời thành viên hát, không ghi tên thành viên hát, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="karaoke.content"/>
            </div>

            <div class="col-span-2" v-if="karaoke.type === 'song'">
              <label class="font-semibold">Tác giả lời <i class="far fa-question-circle"
                                                          :uk-tooltip="'Chọn tác giả lời trong danh sách'"></i></label>
              <multiselect
                  v-model="poets"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchPoets"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>

              <div class="mt-3">
                <div class="keyword-input-container shadow-none">
                    <input @keyup.enter="addPoetToKaraoke" v-model="poet_title" type="text" class="keyword-input with-border" placeholder="Hoặc thêm nhà thơ mới">
                    <button @click="addPoetToKaraoke" type="button" class="keyword-input-button ripple-effect">
                        <i class="icon-feather-plus"></i></button>
                </div>
              </div>
            </div>

            <div class="col-span-2" v-if="karaoke.type === 'folk'">
              <label class="font-semibold">Soạn lời <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn soạn giả trong danh sách'"></i></label>
              <multiselect
                  v-model="recomposers"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchRecomposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>

              <div class="mt-3">
                <div class="keyword-input-container shadow-none">
                    <input @keyup.enter="addRecomposerToKaraoke" v-model="recomposer_title" type="text" class="keyword-input with-border" placeholder="Hoặc thêm soạn giả mới">
                    <button @click="addRecomposerToKaraoke" type="button" class="keyword-input-button ripple-effect">
                        <i class="icon-feather-plus"></i></button>
                </div>
              </div>
            </div>

            <div class="col-span-2" v-if="karaoke.type === 'folk'">
              <label class="font-semibold">Thể loại dân ca <i class="far fa-question-circle"
                                                              :uk-tooltip="'Chọn thể loại trong danh sách'"></i></label>
              <multiselect
                  v-model="fcats"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchFcats"
              >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                </template>
              </multiselect>
            </div>

            <div class="col-span-2" v-if="karaoke.type === 'folk'">
              <label class="font-semibold">Làn điệu <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn làn điệu trong danh sách'"></i></label>
              <multiselect
                  v-model="melodies"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchMelodies"
              >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Trình bày <i class="far fa-question-circle"
                                                        :uk-tooltip="'Chọn người trình bày trong danh sách'"></i></label>
              <multiselect
                  v-model="users"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchUsers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.username
                  }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.username }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label for="record_year" class="font-semibold">Năm thu âm</label>
              <input v-model="karaoke.record_year" class="shadow-none with-border" id="record_year" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="subtitle" class="font-semibold">Ghi chú</label>
              <input v-model="karaoke.subtitle" class="shadow-none with-border" id="subtitle" type="text"
                     autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div>
          <label class="font-semibold">File nhạc (*)</label>
          <DropZone type="music" v-on:uploadDone="handleUploadDone" :musicTypeProp="musicType" :playerOptionsProp="playerOptions"
                    v-model:objectIdProp="karaoke.file_id"/>
        </div>

        <div class="mt-4" v-if="karaoke.file && karaoke.file.type === 'video'">
          <label class="font-semibold">Kiểu hiển thị nhạc</label>
          <select v-model="karaoke.file_type">
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Ảnh minh họa</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="karaoke.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Thể loại</label>
          <select v-model="karaoke.type">
            <option value="song">Tân nhạc</option>
            <option value="folk">Dân ca</option>
            <option value="instrumental">Khí nhạc</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Đóng góp</label>
          <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Bản nhạc liên kết</label>
          <multiselect
              v-model="sheet"
              valueProp="id"
              label="title"
              trackBy="title"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchSheets"
          >
            <template v-slot:option="{ option }">
              {{ option.title }}
              {{ option.year ? ' (' + option.year + ') ' : '' }}
              {{ option.composers && option.composers.data ? ' - ' + getPeopleNames(option.composers.data) : ''}}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                {{ value.title }}
                {{ value.year ? ' (' + value.year + ') ' : '' }}
                {{ value.composers && value.composers.data ? ' - ' + getPeopleNames(value.composers.data) : ''}}
              </div>
            </template>
          </multiselect>
          <div v-if="sheet.id" class="mt-4">
            <router-link class="mr-2" :to="{name: 'AdminSheetEdit', params: {id: sheet.id}}">Sửa bản nhạc</router-link>
          </div>
        </div>

        <div class="mt-7">
          <div class="flex justify-content-between">
            <button type="button"
                    class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    v-on:click="updateKaraoke">
              Cập nhật
            </button>

            <router-link v-if="karaoke.id"
                         :to="{name: 'SongDetail', params: {id: karaoke.id, slug: karaoke.slug, prefix: 'thanh-vien-hat'}}"
                         class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              Xem
            </router-link>

            <a class="text-white hover:text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
               href="#" id="deleteKaraokeBtn" uk-toggle="target: #deleteKaraokeModal">
              Xóa
            </a>
          </div>
        </div>

        <div class="mt-7">
          <div class="flex justify-content-between">
            <button type="button"
                    class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    v-on:click="clearCacheTags">
              Xoá cache
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="deleteKaraokeModal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Xóa thành viên hát</h3>
          <button id="deleteKaraokeModalClose"
                  class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                  type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <p>Bạn có chắc chắn muốn xóa thành viên hát <span class="font-semibold">{{ karaoke.title }}</span>?</p>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="deleteKaraoke"
             class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Xóa thành viên hát</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManageSong, changePageTitle, closeModal, getPeopleNames} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminKaraokeEdit",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      id: null,
      musicType: "audio",
      playerOptions: {
        autoplay: false,
        loop: false,
        controls: true,
        bigPlayButton: true,
        sources: []
      },
      image: null,
      karaoke: {
        id: 0,
        title: "",
        content: "",
        subtitle: "",
        year: "",
        record_year: "",
        file_id: null,
        file_type: "",
        image_id: null,
        sheet_id: null,
        type: "",
        lyric_type: "",
        file: {
          type: "audio"
        }
      },
      uploader: {
        id: null
      },
      sheet: {
        id: null,
        slug: ''
      },
      recomposers: [],
      poets: [],
      fcats: [],
      melodies: [],
      users: [],
      composer_title: "",
      recomposer_title: "",
      poet_title: ""
    }
  },
  methods: {
    loadKaraoke() {
      let query = `query($id: ID!) {
        karaoke(id: $id) {
          id
          title
          slug
          content
          subtitle
          year
          record_year
          file_id
          file_type
          image_id
          type
          lyric_type
          sheet {
            id
            title
            slug
            year
            composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
              data {
                id
                title
                avatar {
                  url
                }
              }
            }
          }
          file {
            id
            audio
            video
            audio_url
            video_url
            type
          }
          thumbnail {
            id
            url
          }
          uploader {
            id
            username
            avatar {
              url
            }
          }
          recomposers(first: 100, orderBy: [{column: "karaoke_recomposer.order", order: ASC}]) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          poets(first: 100, orderBy: [{column: "karaoke_poet.order", order: ASC}]) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          fcats(first: 100) {
            data {
              id
              title
            }
          }
          melodies(first: 100) {
            data {
              id
              title
            }
          }
          users(first: 100, orderBy: [{column: "karaoke_user.order", order: ASC}]) {
            data {
              id
              username
              avatar {
                url
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.karaoke) {
              this.karaoke = data.data.karaoke;
              this.uploader = this.karaoke.uploader;
              if (this.karaoke.sheet) {
                this.sheet = this.karaoke.sheet;
              }

              if (this.karaoke.file) {
                this.musicType = this.karaoke.file.type;
                let src = this.karaoke.file.type === "audio" ? this.karaoke.file.audio_url : this.karaoke.file.video_url;
                let type = this.karaoke.file.type === "audio" ? "audio/mp3" : "video/mp4";
                this.playerOptions.sources[0] = {
                  src: src,
                  type: type
                };
              }

              if (this.karaoke.thumbnail) {
                this.image = this.karaoke.thumbnail.url;
              }
              if (this.karaoke.recomposers && this.karaoke.recomposers.data) {
                this.recomposers = this.karaoke.recomposers.data;
              }
              if (this.karaoke.poets && this.karaoke.poets.data) {
                this.poets = this.karaoke.poets.data;
              }
              if (this.karaoke.fcats && this.karaoke.fcats.data) {
                this.fcats = this.karaoke.fcats.data;
              }
              if (this.karaoke.melodies && this.karaoke.melodies.data) {
                this.melodies = this.karaoke.melodies.data;
              }
              if (this.karaoke.users && this.karaoke.users.data) {
                this.users = this.karaoke.users.data;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateKaraoke() {
      if (!this.karaoke.title) {
        this.$toast.error("Bạn chưa nhập tên bài hát");
        return;
      }

      if (!this.karaoke.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $subtitle: String, $year: String, $record_year: String, $lyric_type: String, $file_type: String, $file_id: ID, $image_id: ID, $sheet_id: ID, $uploader_id: ID, $type: String, $sync_karaoke_recomposer: [SyncKaraokeRecomposer], $sync_karaoke_poet: [SyncKaraokePoet], $sync_karaoke_fcat: [SyncKaraokeFcat], $sync_karaoke_melody: [SyncKaraokeMelody], $sync_karaoke_user: [SyncKaraokeUser]) {
        updateKaraoke(input: {
          id: $id,
          title: $title,
          content: $content
          subtitle: $subtitle
          year: $year
          record_year: $record_year
          file_type: $file_type
          file_id: $file_id
          image_id: $image_id
          sheet_id: $sheet_id
          uploader_id: $uploader_id
          type: $type
          lyric_type: $lyric_type
          recomposers: {
            sync: $sync_karaoke_recomposer
          }
          poets: {
            sync: $sync_karaoke_poet
          }
          fcats: {
            sync: $sync_karaoke_fcat
          }
          melodies: {
            sync: $sync_karaoke_melody
          }
          users: {
            sync: $sync_karaoke_user
          }
        }) {
          id
        }
      }`;

      let sync_karaoke_recomposer = [];
      let sync_karaoke_poet = [];
      let sync_karaoke_fcat = [];
      let sync_karaoke_melody = [];
      let sync_karaoke_user = [];

      this.recomposers.map((item, index) => {
        sync_karaoke_recomposer.push({id: item.id, order: index});
      });
      this.poets.map((item, index) => {
        sync_karaoke_poet.push({id: item.id, order: index});
      });
      this.fcats.map((item) => {
        sync_karaoke_fcat.push({id: item.id});
      });
      this.melodies.map((item) => {
        sync_karaoke_melody.push({id: item.id});
      });
      this.users.map((item, index) => {
        sync_karaoke_user.push({id: item.id, order: index});
      });

      ApiService.graphql(query, {
        id: this.karaoke.id,
        title: this.karaoke.title,
        content: this.karaoke.content,
        subtitle: this.karaoke.subtitle,
        year: this.karaoke.year,
        record_year: this.karaoke.record_year,
        file_type: this.karaoke.file_type,
        file_id: this.karaoke.file_id,
        image_id: this.karaoke.image_id,
        sheet_id: this.sheet ? this.sheet.id : null,
        type: this.karaoke.type,
        lyric_type: this.karaoke.lyric_type,
        uploader_id: this.uploader ? this.uploader.id : null,
        sync_karaoke_recomposer: sync_karaoke_recomposer,
        sync_karaoke_poet: sync_karaoke_poet,
        sync_karaoke_fcat: sync_karaoke_fcat,
        sync_karaoke_melody: sync_karaoke_melody,
        sync_karaoke_user: sync_karaoke_user
      })
          .then(({data}) => {
            if (data.data && data.data.updateKaraoke) {
              this.$toast.success("Cập nhật thành viên hát thành công");
              window.scrollTo(0, 0);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    addRecomposerToKaraoke() {
      if (!this.recomposer_title) {
        this.$toast.error("Bạn chưa nhập tên soạn giả");
        return;
      }

      let query = `mutation($people_title: String!, $people_type: String!, $song_type: String!, $song_id: ID!) {
        addPeopleToSong(
          people_title: $people_title
          people_type: $people_type
          song_type: $song_type
          song_id: $song_id
        ) {
          id
        }
      }`;

      ApiService.graphql(query, {
        people_title: this.recomposer_title,
        people_type: "recomposer",
        song_type: "karaoke",
        song_id: this.karaoke.id
      })
      .then(({data}) => {
        if (data.data && data.data.addPeopleToSong) {
          this.$toast.success("Thêm soạn giả thành công");
          this.recomposer_title = "";
          this.loadKaraoke();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    addPoetToKaraoke() {
      if (!this.poet_title) {
        this.$toast.error("Bạn chưa nhập tên nhà thơ");
        return;
      }

      let query = `mutation($people_title: String!, $people_type: String!, $song_type: String!, $song_id: ID!) {
        addPeopleToSong(
          people_title: $people_title
          people_type: $people_type
          song_type: $song_type
          song_id: $song_id
        ) {
          id
        }
      }`;

      ApiService.graphql(query, {
        people_title: this.poet_title,
        people_type: "poet",
        song_type: "karaoke",
        song_id: this.karaoke.id
      })
      .then(({data}) => {
        if (data.data && data.data.addPeopleToSong) {
          this.$toast.success("Thêm nhà thơ thành công");
          this.poet_title = "";
          this.loadKaraoke();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    deleteKaraoke() {
      let query = `mutation($id: ID!) {
        deleteKaraoke(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.karaoke.id})
          .then(({data}) => {
            if (data.data && data.data.deleteKaraoke) {
              this.$toast.success("Xóa thành viên hát thành công");
              closeModal("deleteKaraokeModal");
              this.$router.push({name: "AdminKaraokeList"});
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchRecomposers(q) {
      let query = `query($q: Mixed) {
        recomposers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.recomposers && data.data.recomposers.data) {
              return data.data.recomposers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchPoets(q) {
      let query = `query($q: Mixed) {
        poets(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.poets && data.data.poets.data) {
              return data.data.poets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchMelodies(q) {
      let query = `query($q: Mixed) {
        melodies(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.melodies && data.data.melodies.data) {
              return data.data.melodies.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchFcats(q) {
      let query = `query($q: Mixed) {
        fcats(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.fcats && data.data.fcats.data) {
              return data.data.fcats.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchSheets(q) {
      let query = `query($q: Mixed, $id: Mixed) {
        sheets(first: 50, orderBy: [{column: "id", order: DESC}], where: {OR: [{column: "title", operator: LIKE, value: $q}, {column: "id", value: $id}]}) {
          data {
            id
            title
            year
            composers(first: 20) {
              data {
                id
                title
                slug
              }
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `%${q}%`, id: q})
          .then(({data}) => {
            if (data.data && data.data.sheets && data.data.sheets.data) {
              return data.data.sheets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    clearCacheTags() {
      let query = `mutation($tags: String!) {
        clearCacheTags(tags: $tags)
      }`;

      ApiService.graphql(query, {
        tags: `karaoke-${this.karaoke.id}`,
      })
      .then(({data}) => {
        if (data.data && data.data.clearCacheTags) {
          this.$toast.success("Xoá cache thành công");
          this.loadKaraoke();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    handleUploadDone(data) {
      if (data && data.type) {
        this.karaoke.file_type = data.type;
        this.karaoke.file.type = data.type;
      }
    },
    getPeopleNames(x) {
      return getPeopleNames(x);
    }
  },
  computed: {
    canManageSong() {
      return canManageSong();
    }
  },
  mounted() {
    if (!canManageSong()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Sửa thành viên hát");
    this.id = this.$route.params.id;
    this.loadKaraoke();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loadKaraoke();
    window.scrollTo(0, 0);
    next();
  },
}
</script>
